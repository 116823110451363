import * as pageTitles from './pageTitles';

export const sitePages: string[][] = [
  [pageTitles.curriculums, '/curriculums/', '/header/icon_book.svg'],
  [pageTitles.whitepapers, '/whitepapers/', '/header/icon_data.svg'],
  [pageTitles.lectures, '/lectures/', '/header/icon_movie.svg'],
  [pageTitles.seminars, '/seminars/', '/header/icon_seminar.svg'],
  [pageTitles.cases, '/cases/', '/header/icon_casestudy.svg'],
  // ['先輩に聞く', ''],
  [pageTitles.about, '/about/', '/header/icon_about.svg'],
];

export const footerLinks: string[][] = [
  ['運営会社', 'https://mov.am/company/'],
  ['プライバシーポリシー', 'https://kutikomi.com/privacy_policy/'],
  ['ご利用規約', '/terms/'],
  ['お問い合わせ', '/contact/'],
];
